footer {
	background: $dark;
	color: $white;
	border-top: 1px solid rgba(255, 255, 255, 0.08);

	.container{
		max-width: 1680px;
	}

	.footer-flex{
		height: 144px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include break(mobile) {
			display: block;
			text-align: center;
			height: auto;
			padding: 50px 0;
		}

		.left{
			@include break(mobile) {
				margin-bottom: 40px;
			}

			.medias_wrap{
				a{
					margin-right: 56px;
					position: relative;
					z-index: 3;

					@include break(mobile) {
						margin: 0 28px;
					}

					svg{
						path{
							transition: .25s;
						}
					}

					&.fb{
						&:hover{
							svg{
								path{
									fill: $lemon;
									fill: $blue;
								}
							}
						}
					}

					&.ig{
						&:hover{
							svg{
								path{
									fill: $lemon;
									fill: $rose;
								}
							}
						}
					}

					&.lkd{
						&:hover{
							svg{
								path{
									fill: $lemon;
									fill: $mauve;
								}
							}
						}
					}
				}
			}
		}

		.right{
			font-size: 16px;
			opacity: 0.5;

			p{
				font-weight: 300;
			}
		}
	}
}
