.template-landing{

	.sec1 {
		padding-top: 80px;
		height: 90vh;
		display: flex;
		align-items: center;
		justify-content: center;
		@include break(mobile) {
			height: auto;
			padding-top: 160px;
			padding-bottom: 60px;
		}
		.flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include break(mobile) {
				flex-direction: column;
			}
		}
		.txt {
			width: 40%;
			@include break(mobile) {
				width: 100%;
				padding-bottom: 40px;
			}
			h1 {
				font-size: 2.7em;
				line-height: 1.2em;
				padding-bottom: 40px;
				@include break(mobile) {
					padding-bottom: 20px;
				}
			}
			h6 {
				@include font-regular;
				font-size: 1em;
			}
		}
		.img_wrap {
			position: relative;
			width: 50%;
			@include break(mobile) {
				width: 100%;
			}
			.img {
				position: relative;
				z-index: 2;
				width: 100%;
				margin-right: auto;
			}
			.rect {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;
				top: 30px;
				right: -30px;
				bottom: -30px;
				@include break(mobile) {
					top: 17px;
					right: -15px;
				}
			}
		}
	}



	.module {
		padding-top: 60px;
		padding-bottom: 60px;
		@include break(mobile) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}


// MODULE TEXTE
.module_texte {
	.container {
		padding-right: 30%;
		@include break(tablet) {
			padding-right: 0;
		}

		h2 {
			padding-bottom: 40px;
			@include break(mobile) {
				padding-bottom: 20px;
			}
		}
	}
}



// MODULE ENCADRE TEXTE
.module_box-texte {
	.container {
		border-radius: 20px;
		text-align: center;
		padding: 80px;
		@include break(mobile) {
			padding: 40px;
		}
		.inner {
			width: 70%;
			margin-left: auto;
			margin-right: auto;
			@include break(tablet) {
				width: 100%;
			}
			h2 {
				padding-bottom: 40px;
				@include break(mobile) {
					padding-bottom: 20px;
				}
			}
			a {
				background: $dark;
				border-radius: 60px;
				margin-top: 40px;
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 25px;
				transition: all 0.4s;
				@include break(mobile) {
					margin-top: 40px;
				}
				svg {
					margin-left: 35px;
					transition: all 0.4s;
				}
			}
		}
	}

	.bg-mauve {
		a:hover {
			background-color: $vert;
		}
	}
	.bg-rose {
		a:hover {
			background-color: $blue;
		}
	}
	.bg-vert {
		a:hover {
			background-color: $rose;
		}
	}
	.bg-lemon {
		a:hover {
			 background-color: $mauve;
		}
	}
	.bg-blue {
		a:hover {
			background-color: $lemon;
		}
	}
}


// MODULE IMAGE TEXTE
.module_2col-image_texte {
	padding-bottom: 80px;
	@include break(mobile) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include break(mobile) {
			flex-direction: column;
		}
	}

	.img_wrap {
		position: relative;
		width: 50%;
		@include break(mobile) {
			width: 100%;
		}
		.img {
			position: relative;
			z-index: 2;
			width: 100%;
			margin-right: auto;
		}
		.rect {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 20px;
			right: -20px;
			bottom: -20px;
			@include break(mobile) {
				top: 15px;
				right: -15px;
			}
		}
	}

	.txt {
		width: 40%;
		@include break(mobile) {
			width: 100%;
			padding-top: 60px;
		}
		h2 {
			font-size: 2.18em;
			line-height: 1.2em;
			padding-bottom: 40px;
			@include break(mobile) {
				padding-bottom: 20px;
			}
		}
		.entry-content {
			@include font-light;
			font-size: .9em;
			line-height: 1.5em;
			p {
				font-size: .9em;
				line-height: 1.5em;
			}
		}
	}

	&_reverse {
		.flex {
			@include break(mobile) {
				flex-direction: column-reverse;
			}
		}
	}
}


// MODULE LOGO
	.module_logo {
		text-align: center;

		.container {
			@include break(mobile) {
				width: 100%;
			}
		}

		h5 {
			text-transform: uppercase;
			@include font-medium;
			font-size: 14px;
			padding-bottom: 40px;
		}
		.logo_wrap {
			display: flex;
			justify-content: space-around;
			&.desktop {
				@include break(mobile) {
					display: none;
				}
			}
			&.mobile {
				display: none;
				@include break(mobile) {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					width: 100vw;
					overflow: hidden;

					.bandeau_logo {
						width: 185%;
						display: flex;

					}
				}
			}
		}
		.logo{
			background: #161617;
			border-radius: 8px;
			display: block;
			padding: 0.5% 1%;
			width: 15%;
			@include break(mobile) {
				width: 150px;
				margin-left: 8px;
				margin-right: 8px;
			}
			img{
				width: 100%;
				height: auto;
			}
		}

		.logo_ghost {
			opacity: 0;
			height: 0;
			display: none;
			@include break(mobile) {
				display: block;
			}
		}

	}


	// MODULE TEMOIGNAGE

	.module_temoignages {
		.flex {
			display: flex;
			justify-content: space-around;
			@include break(mobile) {
				flex-direction: column;
			}

			.tem {
				width: 30%;
				@include break(mobile) {
					width: 100%;
					padding-top: 80px;
					text-align: center;
				}
				&:first-of-type {
					@include break(mobile) {
						padding-top: 0px;
					}
				}
				img {
					display: block;
					width: 96px;
					margin-bottom: 30px;
					@include break(mobile) {
						width: 76px;
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 15px;
					}
				}
				.txt {
					@include font-light;
					padding-bottom: 30px;
					@include break(mobile) {
						padding-bottom: 15px;
					}
				}
				.nom {
					@include font-bold;
					font-size: 1em;
				}
				.poste {
					@include font-light;
					font-size: 0.8em;
				}
			}
		}
	}


// MODULE TAB

.module_img-tab {
	.tab_nav {
		padding-bottom: 30px;
		display: flex;
		justify-content: center;
		@include break(mobile) {
			overflow-x: scroll;
		}
		.tab_name {
			@include font-bold;
			position: relative;
			opacity: 0.4;
			margin: 0px 30px;
			padding-bottom: 12px;
			transition: all 0.4s;
			@include break(mobile) {
				margin: 0px 15px;
			}
			&:after {
				content: " ";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0%;
				height: 1px;
				background-color: $dark;
				display: block;
				transition: all 0.4s;
			}
			&:hover:after {
				width: 100%;
			}

			&.active {
				opacity: 1;
				pointer-events: none;
				&:after {
					width: 100%;
				}
			}
		}
	}
	.tab {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		padding-bottom: 48%;
		@include break(mobile) {
			width: 100%;
			padding-bottom: 56%;
		}
		.tab_img {
			background-size: cover;
			background-position: center;
			width: 100%;
			position: absolute;
			top:0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			opacity: 0;

			&.active {
				opacity: 1;
			}
		}
	}
}


// MODULE AVANTAGE

	.module_avantage {

		h2 {
			padding-bottom: 60px;
			text-align: center;
			@include break(mobile) {
				padding-bottom: 30px;
			}
		}

		.flex {
			display: flex;
			justify-content: space-between;
			@include break(mobile) {
				flex-direction: column;
			}

			.avantage {
				width: 30%;
				@include break(mobile) {
					width: 100%;
					padding-top: 30px;
					text-align: center;
				}
				&:first-of-type {
					@include break(mobile) {
						padding-top: 0px;
					}
				}
				img {
					display: block;
					width: 96px;
					margin-bottom: 30px;
					@include break(mobile) {
						width: 76px;
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 15px;
					}
				}
				h3 {
					font-size: 1.4em;
					padding-bottom: 20px;
				}
				.txt {
					@include font-light;
					padding-bottom: 30px;
					@include break(mobile) {
						padding-bottom: 15px;
					}
				}
			}
		}
	}


	// MODULE FORMULAIRE

	.module_form {
		.flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include break(mobile) {
				flex-direction: column;
			}
		}
		.form_wrapper {
			width: 600px;
			margin-right: 10%;
			flex-grow: 0;
			flex-shrink: 0;
			@include break(mobile) {
				width: 100%;
				margin: 0 auto;
			}
			.form_wrap {
				padding: 60px 70px;
				background: rgba(255, 255, 255, 0.12);
				border: 1px solid rgba(255, 255, 255, 0.4);
				border-radius: 20px;
				@include break(mobile) {
					padding: 20px;
					margin-bottom: 40px;
				}
				.txt_form {
					padding-top: 20px;
					@include font-light;
					font-size: 0.875em;
					line-height: 1.2em;
				}
				form {
					padding-top: 35px;
					position: relative;

					input, select, textarea {
						background: #FFFFFF;
						border-radius: 12px;
						outline: none;
						border: none;
						padding: 16px;
						width: 100%;
						font-size: 18px;
						margin-bottom: 5px;
						margin-top: 5px;

						&[type=submit] {
							background-color: $lemon;
							text-align: left;
							border-radius: 60px;
							@include font-bold;
							padding-left: 24px;
							appearance:none;
							color: $dark;
							transition: all 0.4s;
							&:hover {
								background-color: $vert;
							}
						}
					}

					.phone {
						display: flex;

						.select_wrap {
							position: relative;
							width: 160px;
							margin-right: 12px;
							&:after {
								content: ">";
								pointer-events: none;
								position: absolute;
								top: 16px;
						    right: 12px;
						    transform: rotate(90deg) scale(1.1);
								color: $dark;
								font-weight: 300;
							}
						}
						select {
							width: 100%;
							appearance: none;
						}
					}

					.btn_send {
						position: relative;

						&:after {
							content: "➔";
							pointer-events: none;
							position: absolute;
							top: 14px;
							right: 24px;
							color: $dark;
							transform: scaleY(1.3);
						}
					}
				}
			}

			.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output, .wpcf7 form.payment-required .wpcf7-response-output {
				border: none;
				font-size: 16px;
				padding: 0;
				margin: 0;
			}
			.wpcf7-not-valid-tip {
				font-size: 14px;
			}
			.wpcf7 form.sent .wpcf7-response-output {
		    position: absolute;
		    top: -1.8em;
		    left: -1em;
		    right: -1em;
		    bottom: -1.8em;
		    border: none;
		    background-color: rgba($mauve,0.85);
		    border-radius: 10px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    font-size: 20px;
		    text-align: center;
		    padding: 2em;
		  }

		}
		.list_wrap {
			.item {
				display: flex;
				align-items: flex-start;
				padding-bottom: 60px;
				img {
					width: 60px;
					height: auto;
					margin-right: 15px;
				}

				.txt {
					@include font-light;
					font-size: 0.875em;
					line-height: 1.2em;
				}

				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}
	}


// MODULE FAQ

.module_faq {
	.flex {
		display: flex;
		justify-content: space-between;
		@include break(mobile) {
			flex-direction: column;
		}
	}

	.title_wrap {
		width: 300px;
		@include break(mobile) {
			width: 100%;
			text-align: center;
			margin-bottom: 40px;
		}
	}
	.faq_wrap {
		width: 60%;
		@include break(mobile) {
			width: 100%;
		}

		.faq {
			padding: 30px 0;
			border-top: 1px solid $dark;
			@include break(mobile) {
				padding: 15px 0;
			}
			.question {
				display: flex;
				justify-content: space-between;
				align-items: center;
				span {
					font-size: 1.1em;
					@include font-medium;
				}
				svg {
					width: 20px;
				}
			}
			.answer {
				height: 0;
				overflow: hidden;
				.inner {
					padding-top: 20px;
					@include font-light;
					font-size: 0.875em;
					line-height: 1.4em;
					@include break(mobile) {
						padding-top: 10px;
					}
				}
			}
		}
	}
}


// MODULE SLIDER
	.module.full_slider{
		z-index: 999;
		width: 100%;
		overflow: hidden;

		.nxt{
			position: absolute;
			top: 50%;
			left: 60%;
			z-index: 100;
			width: 60px;
			opacity: 0;
			height: auto;
			pointer-events: none;
			transform: translate(-50%,-50%);

			&.reverse {
				transform: rotate(-180deg) translate(50%,50%);
			}

			rect {
				&.mauve {
					fill: $mauve;
				}
				&.rose {
					fill: $rose;
				}
				&.lemon {
					fill: $lemon;
				}
				&.vert {
					fill: $vert;
				}
				&.blue {
					fill: $blue;
				}

				&.rect_2 {
					transform: translateX(-100%);
				}
			}
		}

		.slider-cont{
			position: relative;


			span.prev,
			span.next{
				display: block;
				width: 50%;
				height: 100%;
				position: absolute;
				top: 0;
				z-index: 2;
			}

			.prev{
				left: 0;
			}

			.next{
				right: 0;
			}
		}

		.full-slider-wrapper{
			width: 72%;
			position: relative;
			padding-bottom: 40.5%;
			margin: 0 auto;
		}



		.slide{
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(210%);

			video,
			img{
				width: 100%;
			}

			&:first-of-type,
			&:nth-of-type(2),
			&:last-of-type{
				//display: block;
				opacity: 1;
			}


			&:nth-of-type(1){
				// left: 50%;
				// transform: translateX(-50%);
				transform: translateX(0%);
				left: 0;
			}

			&:nth-of-type(2){
				transform: translateX(105%);

				// @include break(slide){
				// 	transform: translateX(105%);
				// }
			}

			&:last-of-type{
				//display: block;
				transform: translateX(-105%);

				// @include break(slide){
				// 	transform: translateX(-105%);
				// }
			}

			.slide_txt {
				@include font-light;
				font-size: 0.8em;
				text-align: right;
				padding-top: 5px;
			}
		}
	}

}

.single-landing {
	.transition {
		display: block;
	}
	.rect {
		transform: translateY(0);
	}
}
