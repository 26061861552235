header {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  .alert {
    height: 40px;
    background-color: $lemon;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: .03em;
    color: black;
    overflow: hidden;
    line-height: 1em;
    @include font-medium;
    overflow: hidden;
    margin: 0;
    position: relative;
    z-index: 2;

    .inner {
      padding: 6px 0;
      padding-top: 13px;
      width: 400%;
      height: 100%;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
    }
    .alert_bandeau {
      padding-left: 15px;
      position: relative;
      z-index: 1;
    }
    .close_alert {
      background-color: $lemon;
      height: 40px;
      width: 40px;
      border-left: solid 1px $dark;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      padding-bottom: 5px;
      padding-top: 5px;
      line-height: 1em;
      text-transform: none;
      z-index: 2;
      position: absolute;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: $white;
    transition: opacity 0.6s;
    opacity: 0;

    @include break(tablet) {
      opacity: 1;
    }
  }

  &:hover .bg {
    opacity: 1;
  }

  .header-flex{
    position: relative;
    z-index: 1;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo_wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    @include break(tablet) {
      position: relative;
      z-index: 2;
    }
  }

  .logo {
    width: 160px;
    @include break(tablet) {
      width: 140px;
    }
    path {
      transition: fill 0.4s;
    }
  }

  .nav {
    @include break(tablet) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      // display: flex;
      align-items: center;
      z-index: 1;
    }

    nav {
      @include break(tablet) {
        width: 100%;
      }
    }

    ul.main-nav{
      display: flex;
      @include break(tablet) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .wpml-ls-native{
        font-style: italic !important;
      }

      li{
        margin-left: 40px;
        @include break(tablet) {
          margin-left: 0;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        a{
          background-size: auto 200%;
          color: #000;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-position-y: 0%;
          background-position-x: 200%;
          text-decoration: none;
          font-size: 18px;
          transition: all 0.3s;
          position: relative;
          text-transform: lowercase;

          @include break(tablet) {
            color: $dark!important;
            background: none;
            text-fill-color: $dark!important;
            -webkit-background-clip: $dark!important;
            -webkit-text-fill-color: $dark!important;
            font-size: 48px;
          }
          sup {
            background-size: auto 200%;
            color: #000;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-position-y: 0%;
            background-position-x: 200%;
            transition: all 0.3s;

            @include break(tablet) {
              position: absolute;
              right: -0.8em;
              top: 0.6em;
              transform: translateY(-100%);
              font-size: 0.5em;
              background: none;
              text-fill-color: $dark!important;
              -webkit-background-clip: $dark!important;
              -webkit-text-fill-color: $dark!important;
            }
          }
          background-image: linear-gradient(180deg, $dark 50%, $dark 100%);
          &.hover-mauve {
            background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $mauve 51%, $mauve 100%);
            sup {
              background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $mauve 51%, $mauve 100%);
            }
          }
          &.hover-rose {
            background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $rose 51%, $rose 100%);
            sup {
              background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $rose 51%, $rose 100%);
            }
          }
          &.hover-vert {
            background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $vert 51%, $vert 100%);
            sup {
              background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $vert 51%, $vert 100%);
            }
          }
          &.hover-lemon {
            background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $mauve 51%, $mauve 100%);
            sup {
              background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $mauve 51%, $mauve 100%);
            }
          }
          &.hover-blue {
            background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $blue 51%, $blue 100%);
            sup {
              background-image: linear-gradient(180deg, $dark 50%, $dark 50%, $blue 51%, $blue 100%);
            }
          }

          @include break(tablet) {
            background-image: linear-gradient(180deg, $white 50%, $white 100%);
            &.hover-mauve {
              background-image: linear-gradient(180deg, $white 50%, $white 50%, $mauve 51%, $mauve 100%);
              sup {
                background-image: linear-gradient(180deg, $white 50%, $white 50%, $mauve 51%, $mauve 100%);
              }
            }
            &.hover-rose {
              background-image: linear-gradient(180deg, $white 50%, $white 50%, $rose 51%, $rose 100%);
              sup {
                background-image: linear-gradient(180deg, $white 50%, $white 50%, $rose 51%, $rose 100%);
              }
            }
            &.hover-vert {
              background-image: linear-gradient(180deg, $white 50%, $white 50%, $vert 51%, $vert 100%);
              sup {
                background-image: linear-gradient(180deg, $white 50%, $white 50%, $vert 51%, $vert 100%);
              }
            }
            &.hover-lemon {
              background-image: linear-gradient(180deg, $white 50%, $white 50%, $lemon 51%, $lemon 100%);
              sup {
                background-image: linear-gradient(180deg, $white 50%, $white 50%, $lemon 51%, $lemon 100%);
              }
            }
            &.hover-blue {
              background-image: linear-gradient(180deg, $white 50%, $white 50%, $blue 51%, $blue 100%);
              sup {
                background-image: linear-gradient(180deg, $white 50%, $white 50%, $blue 51%, $blue 100%);
              }
            }
          }

          &:hover {
            background-position-y: 100%;
            sup {
              transition: all 0.3s 0.05s;
              background-position-y: 100%;
            }
          }
        }
      }
    }
  }

  .nav_mobile {
    display: none;
    position: relative;
    cursor: pointer;
    z-index: 2;

    @include break(tablet) {
      display: block;
    }

    .burger {
      height: 22px;
      width: 30px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      overflow: hidden;
      div {
        background-color: $dark;
        width: 100%;
        height: 2px;
        transition: background-color 0.4s;
      }
    }

  }

  .rect {
    display: none;
    @include break(tablet) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      transform: translateY(-100%);
    }
  }

}

body.dark header {

  .bg {
    background-color: transparent;
  }

  .logo path {
    fill: $white;
  }

  @include break(desktop-only) {
    ul.main-nav li a {
      background-image: linear-gradient(180deg, $white 50%, $white 100%);
      &.hover-mauve {
        background-image: linear-gradient(180deg, $white 50%, $white 50%, $mauve 51%, $mauve 100%);
        sup {
          background-image: linear-gradient(180deg, $white 50%, $white 50%, $mauve 51%, $mauve 100%);
        }
      }
      &.hover-rose {
        background-image: linear-gradient(180deg, $white 50%, $white 50%, $rose 51%, $rose 100%);
        sup {
          background-image: linear-gradient(180deg, $white 50%, $white 50%, $rose 51%, $rose 100%);
        }
      }
      &.hover-vert {
        background-image: linear-gradient(180deg, $white 50%, $white 50%, $vert 51%, $vert 100%);
        sup {
          background-image: linear-gradient(180deg, $white 50%, $white 50%, $vert 51%, $vert 100%);
        }
      }
      &.hover-lemon {
        background-image: linear-gradient(180deg, $white 50%, $white 50%, $lemon 51%, $lemon 100%);
        sup {
          background-image: linear-gradient(180deg, $white 50%, $white 50%, $lemon 51%, $lemon 100%);
        }
      }
      &.hover-blue {
        background-image: linear-gradient(180deg, $white 50%, $white 50%, $blue 51%, $blue 100%);
        sup {
          background-image: linear-gradient(180deg, $white 50%, $white 50%, $blue 51%, $blue 100%);
        }
      }
    }
  }

  .burger div {
    background-color: $white;
  }
}

header.dark {

  .logo path {
    fill: $dark!important;
  }

  .burger div {
    background-color: $dark!important;
  }

}
