.template-single-projets{

	.sec1{
		text-align: center;
		padding-top: 130px;

		@include break(v-mobile) {
		  padding-top: 120px;
		}

		.txt{
			margin-bottom: 100px;

			h1{
				font-size: 64px;
				margin-bottom: 12px;

				@include break(v-mobile) {
				  font-size: 36px;
				}
			}

			h6{
				font-size: 22px;
				font-weight: 400;

				@include break(v-mobile) {
				  font-size: 22px;
				}
			}
		}

		.img-wrapper{
			height: 75vh;
			min-height: 550px;
			position: relative;
			overflow: hidden;

			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			@include break(tablet){
				height: 60vh;
				min-height: 400px;
				background-attachment: inherit;
			}

			@include break(mobile){
				height: 50vh;
				min-height: 330px;
				background-attachment: inherit;
			}

			img{
				margin: 0 auto;
				max-width: none;
				width: 100%;

				position: absolute;
				width: 100%;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.video-wrapper {
			video {
				width: 100%;
	    	margin: 0 auto;
			}
		}
	} //.sec1

	.sec2{
		padding-top: 120px;
		padding-bottom: 60px;

		@include break(tablet) {
			padding: 80px 0;
		}

		@include break(v-mobile) {
			padding: 30px 0;
		}

		.flex{
			display: flex;
			justify-content: space-between;

			@include break(tablet){
				display: block;
			}

			.info{
				max-width: 250px;
				margin-right: 120px;

				@include break(tablet){
					max-width: none;
					margin-right: 0;
					margin-bottom: 60px;
				}

				@include break(v-mobile){
					margin-bottom: 30px;
				}

				& * {
					font-size: 16px;
				}

				p{
					margin-bottom: 20px;

					@include break(v-mobile){
						margin-bottom: 10px;
					}

					&:last-of-type{
						margin-bottom: 0;
					}
				}

				a{
					display: inline-block;
					margin-top: 32px;
					text-decoration: underline;

					&:hover{
						color: $mauve;
					}

					@include break(v-mobile){
						margin-top: 25px;
					}
				}
			}

			.txt{
				max-width: 960px;
				flex: 1;

				@include break(tablet){
					max-width: none;
				}

				.txt-block{
					&:first-of-type{
						margin-bottom: 80px;

						@include break(tablet){
							margin-bottom: 40px;
						}
					}
				}
			}
		}
	} //.sec2

	.sec3{ //flexible content
		overflow: hidden;
		z-index: auto;
		.module{
			padding: 60px 0;

			@include break(mobile) {
			  padding: 30px 0;
			}

			@include break(v-mobile) {
			  padding: 25px 0;
			}
		}

		.module.container_img{

			img,
			video{
				width: 100%;
				margin: 0 auto;
			}
		}

		.module.full_img{
			display: flex;
			justify-content: center;

			img,
			video{
				width: 100%;
				margin: 0 auto;
			}
		}

		.module.container_2col_img{
			.container{
				display: flex;
				justify-content: space-between;

				.left,
				.right{
					width: calc((100% - 48px) / 2);

					img,
					video{
						width: 100%;
					}
				}
			}
		} //.module.container_2col_img

		.module.full_2col_img{
			display: flex;

			.left,
			.right{
				width: 50%;

				img,
				video{
					width: 100%;
				}
			}
		}

		.module.container_2col_img-txt{

			.container{
				display: flex;
				// justify-content: space-between;
				align-items: center;

				@include break(tablet){
					display: block;
				}

				.left{
					max-width: 816px;

					@include break(tablet){
						width: 100%;
						max-width: none;
						margin-bottom: 12px;
					}

					img,
					video{
						max-width: 100%;
						height: auto;
						display: block;
					}
				}

				.right{
					max-width: 385px;
					margin-left: 190px;

					@include break(small-screen){
						margin-left: 9vw;
					}

					@include break(tablet){
						max-width: none;
						margin-left: 0;
					}
				}
			}
		} //.module.container_2col_img-txt

		.module.container_2col_txt-img{

			.container{
				display: flex;
				justify-content: space-between;
				align-items: center;

				.right{
					width: 816px;

					img,
					video{
						max-width: 100%;
						height: auto;
					}
				}

				.left{
					max-width: 385px;
					margin-right: 80px;
				}
			}
		} //.module.container_2col_txt-img

		.module.full_img{
			img,
			video{
				margin-left: auto;
				margin-right: auto;
			}
		} //.module.full_img

		.module.full_slider{
			z-index: 999;
			width: 100%;
			overflow: hidden;

			.nxt{
				position: absolute;
				top: 50%;
				left: 60%;
				z-index: 100;
				width: 60px;
				opacity: 0;
				height: auto;
				pointer-events: none;
				transform: translate(-50%,-50%);

				&.reverse {
				  transform: rotate(-180deg) translate(50%,50%);
				}

				rect {
					&.mauve {
						fill: $mauve;
					}
					&.rose {
						fill: $rose;
					}
					&.lemon {
						fill: $lemon;
					}
					&.vert {
						fill: $vert;
					}
					&.blue {
						fill: $blue;
					}

					&.rect_2 {
						transform: translateX(-100%);
					}
				}
			}

			.slider-cont{
				position: relative;


				span.prev,
				span.next{
					display: block;
					width: 50%;
					height: 100%;
					position: absolute;
					top: 0;
					z-index: 2;
				}

				.prev{
					left: 0;
				}

				.next{
					right: 0;
				}
			}

			.full-slider-wrapper{
				width: 72%;
				position: relative;
				padding-bottom: 40.5%;
				margin: 0 auto;
			}



			.slide{
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(210%);

				video,
				img{
					width: 100%;
				}

				&:first-of-type,
				&:nth-of-type(2),
				&:last-of-type{
					//display: block;
					opacity: 1;
				}


				&:nth-of-type(1){
					// left: 50%;
					// transform: translateX(-50%);
					transform: translateX(0%);
					left: 0;
				}

				&:nth-of-type(2){
					transform: translateX(105%);

					// @include break(slide){
					// 	transform: translateX(105%);
					// }
				}

				&:last-of-type{
					//display: block;
					transform: translateX(-105%);

					// @include break(slide){
					// 	transform: translateX(-105%);
					// }
				}
			}
		}

	}//.sec3


	.discover-next{
		overflow: hidden;
		cursor: none;
		position: relative;
		background: transparent;
		height: calc(100vh - 146px);

		@include break(mobile){
			height: auto;
		}

		a {
			cursor: none;
			pointer-events: none;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;

			@include break(mobile) {
				// height: calc(100vh - 146px);
				height: 400px;
				pointer-events: all;
			}

			.textbox{
				text-align: center;
				position: relative;
				z-index: 2;

				h3{
					@include font-regular;
					color: $dark;
					transition: color 0.4s;
				}

				.big-outline{
					font-size: 160px;
					font-weight: 700;
					line-height: 1.25em;
					-webkit-text-stroke: 2px $dark;
					color: transparent;
	        -webkit-background-clip: text,padding-box;
	        background-clip: text,padding-box;
	        background-size: 100% 200%;
	        background-position-y: 0%;
	        background-position-x: center;
					transition: -webkit-text-stroke 0.4s;

					&.mauve {
						background-image:linear-gradient(to top, $mauve 50%, rgba($white,0) 50%);
					}
					&.rose {
						background-image:linear-gradient(to top, $rose 50%, rgba($white,0) 50%);
					}
					&.lemon {
						background-image:linear-gradient(to top, $lemon 50%, rgba($white,0) 50%);
					}
					&.vert {
						background-image:linear-gradient(to top, $vert 50%, rgba($white,0) 50%);
					}
					&.blue {
						background-image:linear-gradient(to top, $blue 50%, rgba($white,0) 50%);
					}

			    	@include break(mobile) {
			    		font-size: 18vw;
			    	}

			    	@include break(v-mobile) {
			    		font-size: 60px;
			    	}
				}
			}

			.cursorProjects {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				overflow: hidden;
				position: absolute;
				text-align: center;
				top: 0;
				left: 0;
				pointer-events: none;
				@include font-bold;
				display: none;
				z-index: 99;

				.txt {
					font-size: 14px;
					line-height: 1.2em;
					text-transform: uppercase;
					text-align: center;
					position: absolute;
					color: $white;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.img_nxt {
				width: 30%;
				padding-bottom: 21%;
				position: absolute;
				background-size: cover;
				background-position: center;
				z-index: 1;
				opacity: 0;
				box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.5);
				transform-origin: center;

				&:nth-of-type(1) {
					top: 0;
					left: 0;
					transform: rotate(-10deg);
				}
				&:nth-of-type(2) {
					top: 13%;
			    right: 5%;
			    transform: rotate(20deg);
				}
				&:nth-of-type(3) {
					top: 63%;
    			left: 10%;
				 	transform: rotate(20deg);
				}
				&:nth-of-type(4) {
					top: 52%;
    			right: 14%;
			    transform: rotate(-15deg);
				}
				&:nth-of-type(5) {
					top: 50%;
    			left: 50%;
			    transform: translate(-50%,-50%);
					z-index: 3
				}
			}

		}
	}

	.has-txt-editor{

		h5{
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.05em;

			.line{
				background: $dark;
				width: 28px;
				height: 1px;
				margin-right: 12px;
			}
		}

		p{
			font-size: 18px;
			font-weight: 300;
			margin-bottom: 20px;
			line-height: 1.6;


			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}

.dark .discover-next {
	h3 {
		color: $white!important;
	}
	.big-outline{
		-webkit-text-stroke: 2px $white!important;
	}
}

.img_fix {
	width: 30%;
	padding-bottom: 21%;
	position: absolute;
	background-size: cover;
	background-position: center;
	z-index: 1;
	opacity: 0;
	box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.5);
	transform-origin: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 999999;
}
