@font-face {
  font-family: "Moderat";
  src: url("../fonts/Moderat-Light.eot");
  src: url("../fonts/Moderat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Moderat-Light.woff2") format("woff2"),
    url("../fonts/Moderat-Light.woff") format("woff"),
    url("../fonts/Moderat-Light.ttf") format("truetype"),
    url("../fonts/Moderat-Light.svg#Moderat-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("../fonts/Moderat-Regular.eot");
  src: url("../fonts/Moderat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Moderat-Regular.woff2") format("woff2"),
    url("../fonts/Moderat-Regular.woff") format("woff"),
    url("../fonts/Moderat-Regular.ttf") format("truetype"),
    url("../fonts/Moderat-Regular.svg#Moderat-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("../fonts/Moderat-Medium.eot");
  src: url("../fonts/Moderat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Moderat-Medium.woff2") format("woff2"),
    url("../fonts/Moderat-Medium.woff") format("woff"),
    url("../fonts/Moderat-Medium.ttf") format("truetype"),
    url("../fonts/Moderat-Medium.svg#Moderat-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("../fonts/Moderat-Bold.eot");
  src: url("../fonts/Moderat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Moderat-Bold.woff2") format("woff2"),
    url("../fonts/Moderat-Bold.woff") format("woff"),
    url("../fonts/Moderat-Bold.ttf") format("truetype"),
    url("../fonts/Moderat-Bold.svg#Moderat-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



