.sec_insta{
	// background: $dark;
	// color: $white;
	height: calc(100vh - 144px);

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	@include break(mobile) {
		// height: calc(100vh - 196px);
		height: 400px;
	}

	.textbox{
		text-align: center;

		h3{
			@include font-regular;
		}

		.big-outline{
			font-size: 160px;
			line-height: 0.7em;
			text-stroke: 2px $dark;
			-webkit-text-stroke: 2px $dark;
	    color: transparent;
			position: relative;
			z-index: 9998;
			transition: all 0.4s;


	    	@include break(mobile) {
	    		font-size: 18vw;
	    	}

	    	@include break(v-mobile) {
	    		font-size: 60px;
	    		-webkit-text-stroke: 1px $dark;
	    	}
		}
	}

	.img_insta {
		position: absolute;
		top:0;
		left:0;
		opacity: 0;
		z-index: 1;
		width: 300px;
		height: 300px;
		background-position: center;
		background-size: cover;
		// display: none;
	}
}

body.dark .sec_insta .big-outline {
	text-stroke: 2px #ffffff;
	-webkit-text-stroke: 2px #ffffff;
	@include break(v-mobile) {
		font-size: 60px;
		-webkit-text-stroke: 1px #ffffff;
	}
}
