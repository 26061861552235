.template-archive-projets{
	// padding-top: 0;

	.sec1{
		// background: $dark;
		// color: white;
		// padding-top: 122px;
		padding-top: 140px;
		padding-bottom: 10vh;
		cursor: grab;

		@include break(tablet){
			padding-top: 130px;
			padding-bottom: 3vh;
		}

		&:active {
			cursor: grabbing;
		}

		.container{
			display: flex;

			@include break(tablet){
				flex-direction: column-reverse;
			}

			h1{
				font-size: 14px;
				opacity: 0.5;
				text-transform: uppercase;
				margin-bottom: 24px;
				letter-spacing: 0.05em;
			}
		}

		.left{
			position: relative;
			z-index: 2;
			padding-top: 100px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include break(xl){
				padding-top: 40px;
			}

			@include break(tablet){
				height: 30vw;
				padding-top: 15px;
			}

			@include break(v-mobile){
				padding-right: 28%;
			}

			.top{
				position: absolute;
				width: 550px;

				@include break(tablet){
					position: relative;
					width: auto;
				}

				h1{
					@include break(tablet){
						display: none;
					}
				}

				h2{
					margin-bottom: 18px;

					@include break(tablet){
						font-size: 7vw;
						margin-bottom: 6px;
					}
				}

				h6{
					margin: 0;
					font-size: 22px;
					font-weight: 400;

					@include break(tablet){
						font-size: 4vw;
					}
				}
			}

			.bottom{
				position: absolute;
				bottom: 0;

				.scroll{
					position: absolute;
					bottom: 0;
					left: -100px;
				}

				.slider_nav{
					font-size: 18px;
					display: flex;
					align-items: center;

					@include break(v-mobile){
						transform: translateY(30px);
					}

					.arrow_right, .arrow_left {
						cursor: pointer;
					}

					.counter{
						margin: 0 18px;
					}
				}
			}


		} //.left

		.right{
			width: 75%;
			padding-bottom: 48%;
			margin-left: auto;
			position: relative;
			overflow: hidden;

			@include break(tablet){
				width: 100%;
				margin-left: 0;
				padding-bottom: 60%;
			}

			@include break(v-mobile){
				overflow: visible;
			}

			h1{
				display: none;

				h1{
					display: block;
				}
			}

			.slide_img_container {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				padding-left: 80px;
				box-sizing: border-box;

				@include break(tablet){
					padding-left: 0;
				}
			}

			.slide_img{
				position: absolute;
				overflow: hidden;
				top: 0;
				left: 80px;
				width: calc(100% - 80px);
				height: 100%;
				overflow: hidden;

				@include break(tablet){
					width: 100%;
					left: 0;
				}

				.slide_img-img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-position: center;
					background-size: cover;
				}

				.overlay{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					transform-style: preserve-3d;
					backface-visibility: hidden;
					transform: translateY(-101%);
					will-change: transform;
				}
			}

			.pastille{
				height: 160px;
				width: 160px;
				display: inline-block;
				position: absolute;
				bottom: 80px;

				@include break(xl){
					bottom: 0;
				}

				@include break(small-screen){
					height: 130px;
					width: 130px;
				}

				@include break(tablet){
					right: 0;
				}

				@include break(mobile){
					width: 130px;
				}

				@include break(v-mobile){
					bottom: -60px;
				}

				svg.txt{
					& *{
						stroke: white !important;
						fill: transparent;
						transition: fill 0.4s;
					}
				}

				.txt{
					width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					animation: 20s rotato infinite linear;
				}

				&:hover {
					svg.txt{
						& * {
							fill: white;
						}
					}
				}

				.icon{
					width: 50%;
					position: absolute;
					top: 50%;
					left: 50%;
					border-radius: 50%;
					transform: translate(-50%, -50%) rotate(0deg);

					circle {
						&.mauve {
							fill: $mauve;
						}
						&.rose {
							fill: $rose;
						}
						&.lemon {
							fill: $lemon;
						}
						&.vert {
							fill: $vert;
						}
						&.blue {
							fill: $blue;
						}
						&.transition {
							display: none;
						}
					}
				}
			}
		}

		.slide_titre_wrap{
			position: relative;

			.slide_titre{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				display: none;
				z-index: 1;

				@include break(tablet){
					// position: static;
				}

				&:first-of-type{
					display: block;
				}

				h2{
					@include break(xl){
						//font-size: 50px;
					}
				}
			}
		}

		.slide_img_wrap > .slide_img_container {
			display: none;
			&:first-of-type{
				display: block;
			}
		}
	}

	.sec2{
		padding: 10vh 0;

		@include break(mobile){
			//padding: 160px 0 60px;
		}

		@include break(v-mobile){
			//padding: 160px 0 20px;
			padding: 40px 0;
		}

		.section-header{
			margin-bottom: 100px;

			@include break(tablet){
				margin-bottom: 60px;
			}

			@include break(v-mobile){
				margin-bottom: 40px;
			}

			.title{
				font-size: 48px;
				line-height: 1em;
				margin-bottom: 60px;
				display: flex;

				@include break(v-mobile){
					font-size: 28px;
					line-height: 1.4;
					margin-bottom: 40px;
				}

				img{
					width: 64px;
					min-width: 64px;
					height: auto;
					display: inline-block;
					margin-left: 4px;
				}
			}

			.filter{
				font-size: 20px;
				display: flex;
				align-items: center;

				@include break(v-mobile){
					font-size: 16px;
				}

				li{
					// opacity: 0.4;
					transition: .25s;
					cursor: pointer;
					margin-right: 63px;
					padding-bottom: 12px;
					border-bottom: 1px solid transparent;
					position: relative;

					@include break(mobile){
						margin-right: 40px;
						padding-bottom: 9px;
					}

					@include break(v-mobile){
						margin-right: 22px;
						padding-bottom: 6px;
					}

					sup{
						// top: -1.1em;
						// left: 0.4em;
						position: absolute;
						top: -2px;
						right: -11px;
					}

					span {
						line-height: 1em!important;
					}

					&.active{
						// opacity: 1;
						border-color: $dark;
					}

					&:hover{
						opacity: 0.7;
						border-color: $dark;
					}
				}
			}
		}//.section-header

		.projects_wrap{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			position: relative;
			overflow: hidden;
			min-height:800px;

			.projet{
				display: block;
				position: relative;
				z-index: 1;
				width: calc((100% - 48px) / 2);
				margin-bottom: 70px;
				transform: translateY(100%);

				@include break(tablet){
					transform: translateY(0%);
					width: calc((100% - 32px) / 2);
				}

				@include break(mobile){
					width: 100%;
					margin-bottom: 60px;
				}

				@include break(mobile){
					margin-bottom: 30px;
				}

				.img{
					padding-bottom: 71%;

					@include break(tablet){
						//height: 350px;
					}

					@include break(mobile){
						// height: 62vw;
					}
				}

				.info{
					padding-top: 13px;
					padding-right: 40px;
					@include break(v-mobile){
						padding-right: 20px;
					}

					h3{
						margin-bottom: 5px;
						opacity: 0;

						@include break(v-mobile){

						}
					}

					h6{
						opacity: 0;
						font-size: 17px;
						font-weight: 300;
						margin: 0;

						@include break(v-mobile){
							font-size: 16px;
						}
					}
				}
			}


		}
	}
}

.dark .template-archive-projets .sec2 .section-header .filter li.active {
	border-color: $white;
}

@keyframes rotato{
	0%{
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100%{
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
