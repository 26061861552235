.template-contact{
	.main-banner{
		padding: 185px 0 80px;

		@include break(mobile){
			padding: 130px 0 60px;
		}

		@include break(v-mobile){
			padding: 130px 0 20px;
		}

		.textbox{
			max-width: 985px;
		}
	}

	.block-place{
		padding: 70px 0;

		@include break(small-screen){
			padding: 50px 0;
		}

		@include break(tablet){
			padding: 30px 0;
		}

		@include break(v-mobile){
			padding: 20px 0;
		}

		.card{
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include break(mobile){
				display: block;
			}

			.img{
				width: 670px;
				height: 840px;

				@include break(small-screen){
					width: 40vw;
					height: 53vw;
				}

				// @include break(tablet){
				// 	width: 46vw;
				// 	height: 58vw;
				// }

				@include break(mobile){
					width: 100%;
					height: 600px;
				}

				@include break(v-mobile){
					height: 414px;
				}
			}

			.txt{

				@include break(mobile){
					padding: 22px 0 0 !important;
				}

				@include break(v-mobile){
					padding: 10px 0 0 !important;
				}

				.titre{
					margin-bottom: 60px;

					@include break(tablet){
						margin-bottom: 52px;
					}

					@include break(mobile){
						margin-bottom: 30px;
					}

					p.value{
						font-weight: 400;
					}
				}

				.tel{
					margin-bottom: 40px;

					@include break(mobile){
						margin-bottom: 20px;
					}
				}

				> * {
					line-height: 1;
				}

				h2{
					margin-bottom: 12px;

					@include break(tablet){
						font-size: 42px;
						line-height: 1.2;
					}

					@include break(mobile){
						font-size: 32px;
						margin-bottom: 7px;
					}
				}

				h5{
					@include font-medium;
					font-size: 14px;
					text-transform: uppercase;
					margin-top: 0;
					margin-bottom: 10px;
					letter-spacing: 0.05em;

					@include break(mobile){
						font-size: 12px;
						margin-bottom: 8px;
					}
				}

				.value{
					@include font-light;
					font-size: 22px;
					text-decoration: none;

					@include break(tablet) {
					  font-size: 20px;
					}

					@include break(mobile){
						font-size: 18px;
					}
				}
			}
		}

		&:nth-of-type(even){
			// border: 4px solid blue;

			.card{
				justify-content: flex-start;
			}

			.txt{
				padding-right: 145px;
				padding-left: 170px;

				@include break(small-screen){
					padding-left: 70px;
				}

				@include break(tablet){
					padding-right: 80px;
					padding-left: 50px;
				}
			}
		}

		&:nth-of-type(odd){
			// border: 4px solid red;

			.img{
				order: 2;

				@include break(mobile){
					order: 0;
				}
			}

			.txt{
				order: 1;
				padding-left: 170px;
				padding-right: 100px;

				@include break(tablet){
					padding-left: 80px;
					padding-right: 50px;
				}
			}
		}
	}

	.secLast{
		text-align: center;
		padding-top: 146px;
		padding-bottom: 200px;

		@include break(mobile){
			padding: 90px 0;
		}

		@include break(v-mobile){
			padding: 50px 0;
			// min-height: 500px;
		}

		h2{
			margin-bottom: 60px;

			@include break(tablet){
				font-size: 40px;
			}

			@include break(mobile){
				margin-bottom: 20px;
				font-size: 32px;
			}

			br{
				// @include break(v-mobile){
				// 	display: none;
				// }
			}
		}

		.medias_wrap{
			display: flex;
			justify-content: center;

			a{
				display: inline-block;
				margin: 0 30px;
				width: 91px;
				height: 91px;
				background: #ff99c8;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				transition: .25s;

				@include break(mobile){
					width: 60px;
					height: 60px;
					margin: 0 17px;
				}

				.icon-holder{
					width: 37px;
				 	height: auto;
				 	display: block;
				}

				&:hover{
					background: $vert !important;
				}

				&.icon-fb{
					background: $blue;
				}

				&.icon-ig{
					background: $rose;
				}

				&.icon-lk{
					background: $mauve;
				}

				svg{
					@include break(mobile){
						width: 30px;
						height: 30px;
					}
				}
			}
		}
	}


}

.page-template-template-contact{
	.sec_insta{
		@include break(v-mobile){
			display: none;
		}
	}
}
