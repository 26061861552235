html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $white;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
   scroll-behavior: smooth;
}

body {
  &.overflow{
    overflow: hidden;
  }
  // overflow-x: hidden;
  // overflow-y: scroll;
  // overflow: -moz-scrollbars-none;
  // -ms-overflow-style: none;
  @include font-regular;
  background-color: $white;
  color: $dark;
  font-size: $font-size;
  @include break(mobile){
    font-size: 16px;
    overflow-x: hidden;
    // overflow-y: scroll;
  }
  @include font-regular;
  font-style: normal;
  transition: background-color 0.4s, color 0.4s;

  &.dark {
    background-color: $dark;
    color: $white;
  }
   scroll-behavior: smooth;
}

* {
  @include break(desktop-only) {
    cursor: none!important;
  }
}

//test fix bug loading mobile centered
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

.grecaptcha-badge {
  visibility: hidden;
}

.cursor {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: fixed;
  pointer-events: none;
  transform-origin: center center;
  z-index: 99999;
  @include break(tablet) {
    display: none;
  }

  .border {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid $dark;
  }

  .click {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transform: scale(0);
    opacity: 0;
    border-radius: 50%;
    z-index: -1;
  }
}

body.dark .cursor .border, .cursor.dark .border {
  border: 1px solid $white;
}




.scrollContainerWrap {
  overflow: hidden;
  position: relative;
}

.sticker_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  pointer-events: none;

  .sticker, .sticker_ghost {
    pointer-events: none;
    width: 60px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  .sticker_ghost {
    display: none;
    visibility: hidden;
  }

}

.cursor-light {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background: radial-gradient(rgba($dark, 0.8) 0%, rgba($dark, 0) 100%);
  position: fixed;
  pointer-events: none;
  transform-origin: center center;
  z-index: 2;
  opacity: 0;
  filter: blur(50px);
  @include break(tablet) {
    display: none;
  }
}

body.dark .cursor-light {
  background: radial-gradient(rgba($dark, 0.8) 0%, rgba($dark, 0) 100%);
  opacity:1;
  transition: opacity 0.4s 0.4s;
}

// TEMPORAIRE V1
 .loader {
   display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 365px;
      overflow: visible;
      transform-origin: center;
      transform-box: fill-box;
      @include break(mobile){
        width: 200px;
      }
    }
    .logo_new-wrap {
      width: 400px;
      height: 60px;
      overflow: hidden;
      @include absolute-center(center);

      .logo_new {
        transform-origin: center;
        transform-box: fill-box;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
      @include break(mobile){
        width: 240px;
        height: 38px;
      }
    }

  }
  .letter {
    position: relative;
    transform-origin: center;
    transform-box: fill-box;
    image {
      opacity: 0;
      transform-origin: center;
      transform-box: fill-box;
    }
    g {
      transform-origin: center;
      transform-box: fill-box;
    }
  }
  .rect {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateY(-100%);
  }
}

.home .loader {
  display: block;
}


.transition {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 99998;
  display: none;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: $dark;
    text-align: center;
    .logo {
      width: 200px;
      @include absolute-center(center);
    }
  }
  .rect {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateY(-100%);
  }
}


.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1390px;
}

.container-lg{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1680px;
}

.btn{
  font-size: 22px;
  transition: .25s;

  @include break(v-mobile){
    font-size: 18px;
  }

  &.has-icon{
    display: inline-flex;
    align-items: center;
    text-decoration: underline;

    .icon{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid rgba(5, 15, 16, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 18px;
      transition: .25s;

      @include break(v-mobile){
        width: 32px;
        height: 32px;
      }

      svg{
        path{
          transition: .25s;
        }
      }
    }

    &:hover{
      .icon{
        background: $dark;

        svg{
          path{
            fill: $white;
          }
        }
      }
    }
  }
}

.dark .btn.has-icon {
  .icon {
    border: 1px solid $white;
  }
  svg{
    path{
      fill: $white;
    }
  }

  &:hover{
    .icon{
      background: $white;

      svg{
        path{
          fill: $dark;
        }
      }
    }
  }
}

.noise, .noise_loader {
	z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	opacity: .07;
  filter: brightness(0.5);
  transition: filter 0.4s;
}

.dark .noise, .noise_loader {
  filter: brightness(1);
}


.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}

.bg-img{
  background-size: cover;
  background-position: center;
}

.font-light{
  @include font-light;
}

a{
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  margin: 0;

  @include font-bold;

  a {
    color: inherit;
    text-decoration: none;
  }
}

section {
    position: relative;
    z-index: 3;
}

h1{
  font-size: 70px;

  @include break(mobile) {
    font-size: 60px;
  }

  @include break(v-mobile) {
    font-size: 44px;
  }
}

h2{
  font-size: 60px;
  line-height: 1.3;

  @include break(mobile) {
    font-size: 42px;
  }

  @include break(v-mobile) {
    font-size: 24px;
  }
}

h3{
  font-size: 35px;

  @include break(tablet) {
    font-size: 32px;
  }

  @include break(v-mobile) {
    font-size: 24px;
  }
}


.bg-mauve {
  background-color: $mauve;
}
.bg-rose {
  background-color: $rose;
}
.bg-vert {
  background-color: $vert;
}
.bg-lemon {
  background-color: $lemon;
}
.bg-blue {
  background-color: $blue;
}

.txt-mauve {
  color: $mauve;
}
.txt-rose {
  color: $rose;
}
.txt-vert {
  color: $vert;
}
.txt-lemon {
  color: $lemon;
}
.txt-blue {
  color: $blue;
}

.fill-mauve {
  fill: $mauve;
}
.fill-rose {
  fill: $rose;
}
.fill-vert {
  fill: $vert;
}
.fill-lemon {
  fill: $lemon;
}
.fill-blue {
  fill: $blue;
}

.select-mauve *::selection {
  background: $mauve!important;
  color: $white;
}
.select-rose *::selection {
  background: $rose!important;
  color: $white;
}
.select-vert *::selection {
  background: $vert!important;
  color: $white;
}
.select-lemon *::selection {
  background: $lemon!important;
  color: $white;
}
.select-blue *::selection {
  background: $blue!important;
  color: $white;
}



span.circle, span.highlight{
  position: relative;

  span {
    background-color: #52F5D1;
    height: 0%;
    width: 107%; //longer than the word
    width: 100%;
    position: absolute;
    left: 0;
    right: 7%;
    bottom: 15%;
    z-index: -1;
    transform-origin: bottom;
  }
}

.span_circle_model {
  display: none;
  visibility: hidden;
}

.gradient {
  display: inline;
  background: $gradient;
  background-size: 500% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position-y: 500%;
  background-position-x: 0%;
  animation: gradient 30s linear infinite;

  @include break(mobile){
    display: inline-block;
  }

  &.gradient-long {
    background-size: 200% auto;
    background-position-y: 200%;
    animation: gradient-long 10s linear infinite;
  }

  &:nth-of-type(2) {
    background-position-x: 400%;
    animation: gradient-delay 30s linear infinite;
  }
}


.anime-overlay, .anime-projet, .anime-2col {
  &_wrap {
    position: relative;
  }
  &_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
}

.anime-txt_step {
  opacity: 0;
}

@keyframes gradient-delay {
  from {
    background-position-x: 400%;
  }
  to {
    background-position-x: 900%;
  }
}

@keyframes gradient {
  from {
    background-position-x: 0%;
  }
  to {
    background-position-x: 500%;
  }
}

@keyframes gradient-long {
  to {
    background-position-x: 200%;
  }
}


.legal-notices{
  padding: 120px 0;

  @include break(v-mobile){
    padding: 60 0 40px;
  }
}

.entry-content{

  h1, h2, h3, h4, h5, h6{
    margin-top: 1.5em;
    margin-bottom: .5em;

    @include break(v-mobile){
      margin-top: 1.2em;
    }
  }

  p{
    margin-bottom: 1em;
    line-height: 1.3em;
  }

  a{
    color: $vert;
    text-decoration: underline;
    transition: .25s;

    &:hover{
      color: $mauve;
    }
  }

  ul {
    list-style: none;
    padding-left: 40px;
    li {
      position: relative;
      &:before {
        content: "·";
        font-size: 24px;
        position: absolute;
        top: 0;
        left: -20px;
      }
    }
  }
}
