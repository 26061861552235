.oxo-view.template-home {

	.sec1{
		// background: $dark;
		// color: $white;
		min-height: 100vh;

		.container_txt{
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 0;
			position: relative;
			width: 75%;
			margin-left: auto;
			margin-right: auto;
			justify-content: center;
			padding-top: 72px;
			padding-bottom: 29vh;
			
			@include break(small-screen) {
				width: 90%;
			}

			@include break(mobile) {
				padding-bottom: 21vh;
			}
		}

		h1{
			position: relative;
			z-index: 9997;
			max-width: 1000px;
			@include break(small-screen) {
				font-size: 60px;
			}
			@include break(mobile) {
				font-size: 42px;
			}

			br{
				@include break(mobile){
					display: none;
				}
			}
		}

		.logo_wrap{
			display: flex;
			justify-content: space-between;
			position: absolute;
			width: 100%;
			left: 50%;
			bottom: 13vh;
			transform: translateX(-50%);

			@include break(v-mobile){
				bottom: 18vh;
			}

			.logo{
				background: #161617;
	    		border-radius: 8px;
	    		display: block;
			    padding: 0.5% 1%;
			    width: 15%;
				@include break(mobile) {
					width: 150px;
					margin-left: 8px;
					margin-right: 8px;
				}

				img{
					width: 100%;
					height: auto;
				}
			}

			&.desktop {
				@include break(mobile) {
					display: none;
				}
			}
			&.mobile {
				display: none;
				@include break(mobile) {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					width: 100vw;
					overflow: hidden;

					.bandeau_logo {
						width: 185%;
						display: flex;

					}
				}
			}
		}

		a.scroll{
			position: absolute;
			font-size: 12px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: .1em;
			opacity: 0.5;
			transform-origin: center;
			bottom: 28vh;
			left: 6.25%;
			line-height: 1em;
			transform: rotate(-90deg) translateY(-30px);
			@include break(small-screen) {
				left: 2.5%;
			}
			@include break(tablet) {
				display: none;
			}
		}

		.img_wrap .img {
			position: absolute;
			top:0;
			left:0;
			opacity: 0;
			z-index: 0;
			width: 350px;
			height: 280px;
			background-position: center;
			background-size: cover;
		}
	}

	.sec2{
		.titre-section{
			padding-top: 120px;
			padding-bottom: 60px;
			max-width: 908px;

			@include break(tablet){
				padding: 140px 0;
			}

			@include break(v-mobile){
				padding-top: 2vh;
				padding-bottom: 20px;
			}

			h3{
				font-weight: 400;

				@include break(tablet){
					font-size: 32px;
				}

				@include break(v-mobile){
					font-size: 24px;
				}

				br{
					@include break(mobile){
						display: none;
					}
				}

				.circle{
					display: inline-block;
				}
			}

			.btn{
				margin-top: 50px;


			}
		} //.titre

		.projets{
			display: flex;
			justify-content: flex-end;
			position: relative;
			padding: 100px 0;

			@include break(tablet){
				display: block;
				padding: 30px 0;
			}

			@include break(v-mobile){
				padding: 20px 0;
			}

			.titre{
				max-width: 550px;
				position: absolute;
				left: 0;
				z-index: 1;
				padding-top: 80px;

				@include break(tablet){
					position: static;
					padding-top: 8px;
				}

				@include break(v-mobile){
					padding-right: 135px;
				}

				h2{
					margin-bottom: 6px;

					@include break(v-mobile){
						margin-bottom: 0;
					}
				}

				h6{
					margin: 0;
					font-size: 22px;
					font-weight: 300;

					@include break(v-mobile){
						font-size: 16px;
					}
				}
			}

			.img{
				position: relative;
				background-size: cover;
				background-position: center;

				@include break(tablet){
					width: 100% !important;
				}

				.pastille{
					height: 165px;
					width: 165px;
					display: inline-block;
					position: absolute;
					bottom: 80px;
					transform: translateX(-50%);

					@include break(small-screen){
						height: 130px;
						width: 130px;
						bottom: 20px;
					}

					@include break(tablet){
						bottom: -64px;
						left: auto;
						right: 0px;
						transform: none !important;
					}

					@include break(mobile){
						width: 130px;
					}

					svg.txt{
						& *{
							stroke: $dark !important;
							fill: transparent;
							transition: fill 0.4s;
						}
					}

					&:hover {
						svg.txt{
							& * {
								fill: white;
							}
						}
					}

					.txt{
						width: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						animation: 20s rotato infinite linear;
					}

					.icon{
						width: 50%;
						position: absolute;
						top: 50%;
						left: 50%;

						transform: translate(-50%, -50%) rotate(0deg);

						circle {
							&.mauve {
								fill: $mauve;
							}
							&.rose {
								fill: $rose;
							}
							&.lemon {
								fill: $lemon;
							}
							&.vert {
								fill: $vert;
							}
							&.blue {
								fill: $blue;
							}
							&.transition {
								display: none;
							}
						}
					}
				}
			}

			&.projet-1{
				.img{
					width: 960px;
					padding-bottom: 51%;
					background-position: center;

					@include break(small-screen){
						width: 74vw;
					}

					@include break(v-mobile){
						padding-bottom: 70%;
					}
				}
			}

			&.projet-2{
				justify-content: flex-start;
				// flex-direction: row-reverse;

				.titre{
					// left: auto;
					// right: 0;
					position: static;
					margin-left: 120px;
					order: 2;

					@include break(small-screen){
						margin-left: 9vw;
					}

					@include break(tablet){
						margin-left: 0;
					}
				}

				.img{
					order: 1;
					width: 672px;
					padding-bottom: 52%;


					@include break(small-screen){
						width: 60vw;
					}

					@include break(v-mobile){
						// padding-bottom: 90%;
						padding-bottom: 70%;
					}

					.pastille{
						left: auto;
						right: 0;
						transform: translateX(50%);
					}
				}
			}

			&.projet-3{
				.img{
					width: 816px;
					padding-bottom: 44%;

					@include break(small-screen){
						width: 66vw;
					}

					@include break(v-mobile){
						padding-bottom: 70%;
					}
				}
			}
		}
	} //.sec2

	.sec3{
		padding: 100px 0;

		@include break(tablet){
			padding: 50px 0;
		}

		@include break(v-mobile){
			padding: 30px 0;
		}

		.bandeau_container{
			width: 100vw;
  		overflow: hidden;
  		white-space: nowrap;
			background-color: transparent;
			display: flex;
    	flex-wrap: nowrap;
			margin: 0;
			padding: 0;

			&.bandeau_container1 .bandeau {
				background-image: linear-gradient(90deg, $vert 0%, $vert 10%, rgba($white,0) 10.01%, rgba($white,0) 28%, $mauve 28.01%, $mauve 51.99%, $vert 52%, $vert 60%, rgba($white,0) 60.01%, rgba($white,0) 78%, $mauve 78.01%, $mauve 92%, rgba($white,0) 92.1%);
			}

			&.bandeau_container2 .bandeau {
				background-image: linear-gradient(90deg, rgba($white,0) 0%, rgba($white,0) 15%, $lemon 15.01%, $lemon 35%, rgba($white,0) 35.01%, rgba($white,0) 65%, $lemon 65.01%, $lemon 85%, rgba($white,0) 85.01%, rgba($white,0) 100%);
			}

			&.bandeau_container3 .bandeau {
				background-image: linear-gradient(90deg, $blue 0%, $blue 15%, rgba($white,0) 15.01%, rgba($white,0) 37%, $rose 37.01%, $rose 51.99%, $blue 52%, $blue 65%, rgba($white,0) 65.01%, rgba($white,0) 87%, $rose 87.1%, $rose 100%);
			}


			.bandeau{
				display: inline-flex;
				margin: 0;
				padding: 0;
				flex-shrink: 0;
				width: 100vw;
		    flex-direction: row;
		    flex-wrap: nowrap;
		    justify-content: space-around;
		    align-items: center;
				background-size: 200%;
				background-position-y: center;
				background-position-x: 0%;
				background-clip: text;
				text-fill-color: transparent;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				-webkit-box-decoration-break: clone;

				.line,
				.txt{
					// margin-left: 20px;
					// margin-right: 20px;
					display: inline;
					font-size: 5.5vw;
					font-weight: 700;
					line-height: 1.3em;
					color: transparent;
					-webkit-text-stroke: 1px #050F10;
					transition: all 0.4s;
					@include break(mobile) {
						font-size: 9.3vw;

						&:last-of-type{
							display: none;
						}
					}
				}


			}


		}

		.btn{
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 80px;

			@include break(tablet){
				margin-top: 60px;
			}

			@include break(v-mobile){
				margin-top: 30px;
			}
		}
	}
}

.dark .bandeau .txt {
	-webkit-text-stroke: 1px #ffffff!important;
}
