.template-apropos{
	.sec1{
		padding-top: 140px;
		padding-bottom: 60px;

		@include break(mobile){
			padding-top: 130px;
			padding-bottom: 0;
		}

		@include break(v-mobile){
			// padding-top: 60px;
		}

		.titre{
			max-width: 490px;
			margin-bottom: 100px;

			@include break(mobile){
				margin-bottom: 80px;
			}

			@include break(v-mobile){
				margin-bottom: 30px;
			}
		}

		.txt{
			max-width: 815px;
			margin-left: auto;
			font-size: 22px;
			font-weight: 300;

			@include break(v-mobile){
				font-size: 22px;
			}

			p{
				@include break(mobile){
					font-size: 18px;
				}
			}
		}
	}

	.sec2{
		padding: 80px 0 0px 0; //a voir
		z-index: 999;

		@include break(mobile){
			padding-top: 10vw;
		}

		.nxt{
			position: absolute;
			// position: fixed;
			top: 50%;
			left: 60%;
			z-index: 100;
			width: 60px;
			opacity: 0;
			height: auto;
			pointer-events: none;
			transform: translate(-50%,-50%);

			&.reverse {
			  transform: rotate(-180deg) translate(50%,50%);
			}

			rect {
				&.mauve {
					fill: $mauve;
				}
				&.rose {
					fill: $rose;
				}
				&.lemon {
					fill: $lemon;
				}
				&.vert {
					fill: $vert;
				}
				&.blue {
					fill: $blue;
				}

				&.rect_2 {
					transform: translateX(-100%);
				}
			}
		}

		.about_slider{
			width: 100%;
			position: relative;
			padding-bottom: 40%;
			cursor: none;

			span.prev,
			span.next{
				display: block;
				width: 50%;
				height: 100%;
				position: absolute;
				top: 0;
				z-index: 2;
			}

			.prev{
				left: 0;
			}

			.next{
				right: 0;
			}

			.slide{
				width: 72%;
				height: 100%;
				background-size: cover;
				background-position: center;
				position: absolute;
				top: 0;
				left: $margin-slide;
				bottom: 0;
				opacity: 0;
				// display: none;

				@include break(slide){
					left: 5%;
				}


				&:nth-of-type(1),
				&:nth-of-type(2){
					opacity: 1;
				}

				&:nth-of-type(2){
					transform: translateX(calc(100% + $margin-slide));

					@include break(slide){
						transform: translateX(105%);
					}
				}
			}
		}
	}

	.sec3 { //cards
		padding-bottom: 47%;
		@include break(tablet){
			padding-top: 40px;
		}

		// @include break(mobile){
		// 	min-height: 600px;
		// }

		// @include break(v-mobile){
		// 	min-height: 630px;
		// }

		.container-lg {
			position: relative;
			min-height: 1840px;
			padding-bottom: 80px;

			@include break(tablet){
				padding-bottom: 40px;
				min-height: 0px;
			}
		}

		.card{
			padding: 60px 40px 60px 10%;
			border-radius: 32px;
			display: flex;
			justify-content: space-between;
			position: absolute;
			top: 80px;
			left: 0;
			right: 0;
			min-height: 580px;

			@include break(small-screen){
				padding: 60px 40px 60px 100px;
				padding: 60px;
			}

			@include break(tablet){
				position: relative;
				padding: 50px;
				height: auto;
				min-height: 0;
				margin-top: 40px;
				top: 0px;
			}

			@include break(mobile){
				padding: 40px;
			}

			@include break(v-mobile){
				padding: 60px 20px;
			}

			.left{
				width: 50%;
				padding-top: 47px;
				flex-shrink: 1;

				@include break(small-screen){
					padding-top: 0;
				}

				@include break(mobile){
					max-width: none;
					width: 100%;
					margin-right: 0;
				}

				h3{
					font-size: 50px;
					margin-bottom: 18px;

					@include break(tablet){
						font-size: 45px;
					}

					@include break(mobile){
						font-size: 40px;
						margin-bottom: 22px;
					}
				}

				.txt{
					font-weight: 300;
					font-size: 20px;
					@include break(small-screen){
						font-size: 18px;
					}
				}

				.btn{
					font-size: 18px;
					margin-top: 40px;
				}
			}

			.right{
				width: 40%;
				@include break(mobile){
					display: none;
				}

				.img{
					width: 100%;
					padding-bottom: 105%;
					border-radius: 18px;
					background-size: cover;
					background-position: center;
				}
			}

			&:nth-of-type(1) {
				z-index: 3;
			}
			&:nth-of-type(2) {
				z-index: 2;
				transform: translateY(7%);
				@include break(tablet){
					transform: translateY(0);
				}
			}
			&:nth-of-type(3) {
				z-index: 1;
				transform: translateY(14%);
				@include break(tablet){
					transform: translateY(0);
				}
			}
		}
	}

	.sec4{
		padding-top: 100px;
		padding-bottom: 40px;

		@include break(tablet){
			padding: 80px 0;
		}

		@include break(mobile){
			padding: 60px 0;
		}

		@include break(v-mobile){
			padding-top: 50px;
			padding-bottom: 40px;
		}

		.textbox{
			max-width: 908px;

			h3{
				font-size: 25px;
				line-height: 1.3;
				font-weight: 300;

				@include break(tablet){
					font-size: 30px;
				}

				@include break(mobile){
					font-size: 25px;
				}

				@include break(v-mobile){
					font-size: 20px;
				}
			}

			.titre{ //teste
				// position: relative;
				// z-index: 1;
			}

			.btn{
				margin-top: 44px;
			}
		}
	}
}
