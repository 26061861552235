/*  --- Variable ---  */

//FONT
@mixin font-light{
  font-family: 'Moderat', sans-serif;
  font-weight: 300;
}

@mixin font-regular{
  font-family: 'Moderat', sans-serif;
  font-weight: 400;
}

@mixin font-medium{
  font-family: 'Moderat', sans-serif;
  font-weight: 500;
}

@mixin font-bold{
  font-family: 'Moderat', sans-serif;
  font-weight: 700;
}

/* --- Variables for fonction --- */

$font-size: 22px;

$margin-slide: calc((100% - 1680px) / 2);
//COLOR

$dark: #070709;
$white: #ffffff;
$mauve: #E4C1F9;
$rose: #FF99C8;
$vert: #52F5D1;
$lemon: #F1FF70;
$blue: #A9DEF9;

$gradient: linear-gradient(90deg, $rose 5%, $mauve 18%, $blue 31%, $vert 44%, $lemon 57%, $vert 70%, $blue 83%, $mauve 96%, $rose 100%);
